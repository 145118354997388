<template>
  <div class="profile">
    <v-container>
      <v-col cols="10" offset="1" align="center">
        <v-card-title class="text-center justify-center py-6">
          <h3 class="font-weight-bold display-1">Modificar información</h3>
        </v-card-title>
      </v-col>
      <v-tabs v-model="tab" background-color="transparent" grow>
        <v-tab href="#cliente"> Cliente </v-tab>
        <v-tab href="#documentacion"> Documentación </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item value="cliente">
          <v-card class="mt-5" flat>
            <template v-slot:default>
              <v-container>
                <v-col cols="12">
                  <div>
                    <v-col cols="12" sm="12" md="12">
                      <h2 class="text-center">Datos Perfil</h2>
                    </v-col>
                  </div>
                  <v-row>
                    <v-col cols="12" offset-md="2" md="8" offset-sm="2" sm="8" offset-lg="4" lg="4">
                      <section>
                        <v-row>
                          <v-col cols="6" align="right">
                            <input type="radio" id="personaFisica" value="1" v-model="tipoCliente" />
                            <label for="personaFisica">&nbsp;Persona f&iacute;sica</label>
                          </v-col>
                          <v-col cols="6" align="left">
                            <input type="radio" id="empresa" value="0" v-model="tipoCliente" />
                            <label for="empresa">&nbsp;Empresa</label>
                          </v-col>
                        </v-row>
                      </section>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" offset-md="2" md="8" offset-sm="2" sm="8" offset-lg="4" lg="4">
                      <v-form>
                        <div v-if="tipoCliente === '1'">
                          <v-text-field label="Nombre" v-model="nombre"> </v-text-field>
                          <v-text-field label="Apellido" v-model="apellido"> </v-text-field>
                          <v-text-field label="Número de DNI" v-model="dni"> </v-text-field>
                        </div>
                        <div v-if="tipoCliente === '0'">
                          <v-text-field label="Razón social" v-model="razonSocial"> </v-text-field>
                          <v-text-field label="Número de CUIT" v-model="cuit"> </v-text-field>
                        </div>
                        <v-autocomplete
                          v-model="sectorProductivo"
                          :items="sectorProductivos"
                          required
                          label="Sector productivo"
                        ></v-autocomplete>
                        <v-text-field label="Dirección de e-mail" v-model="email"> </v-text-field>
                        <div class="text-center">
                          <v-btn color="success" @click="modificar"><v-icon left> mdi-pencil</v-icon> Modificar </v-btn>
                        </div>
                      </v-form>
                    </v-col>
                  </v-row>
                </v-col>
              </v-container>
            </template>
          </v-card>
        </v-tab-item>
        <v-tab-item value="documentacion">
          <v-card class="mt-5" flat>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">Tipo</th>
                      <th class="text-center">Fecha Actualizado</th>
                      <th class="text-center">Archivo</th>
                      <th class="text-center">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in documentos" :key="item.id">
                      <td>{{ item.tipo }}</td>
                      <td v-if="item.updatedAt">{{ transformDate(item.updatedAt) }}</td>
                      <td v-else></td>
                      <td>
                        <v-file-input
                          v-if="!item.id"
                          v-model="item.value"
                          class="mt-5"
                          label="Subir archivo"
                          outlined
                          dense
                          show-size
                        ></v-file-input>
                        <span v-if="item.id">{{ item.nombre }}</span>
                      </td>
                      <td>
                        <v-btn
                          :disabled="item.id > 0"
                          v-on:click="subirDoc(item.value, item.documentacion_tipo)"
                          color="primary"
                        >
                          <v-icon>mdi-upload</v-icon>
                        </v-btn>
                        <v-btn :disabled="item.id < 1" v-on:click="verDoc(item.url)" color="success">
                          <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                        <v-btn :disabled="item.id < 1" v-on:click="eliminarDoc(item.id)" color="error">
                          <v-icon> mdi-delete </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-row>
        <v-col cols="12" offset-md="3" md="6" offset-sm="2" sm="8">
          <v-alert :value="alertOk" border="bottom" color="green" dense elevation="5" prominent type="success">
            <v-row align="center">
              <v-col class="grow">{{ this.mensaje }}</v-col>
            </v-row>
          </v-alert>
          <v-alert :value="alertError" border="bottom" color="red" dense elevation="8" prominent type="error">
            <v-row align="center">
              <v-col class="grow">{{ this.mensaje }}</v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { API_URL } from '@/common/config';

const axios = require('axios');

export default {
  name: 'Profile',
  data: () => ({
    mostrarLoading: false,
    tipo: '',
    valid: true,
    email: '',
    password: '',
    telefono: '',
    nombre: '',
    apellido: '',
    cuit: '',
    dni: '',
    razonSocial: '',
    sectorProductivo: '',
    sectorProductivos: [],
    mensaje: '',
    tipoCliente: '1',
    user: [],
    client: [],
    cliente: '',
    tab: [],
    archivo1: null,
    archivo2: null,
    token: localStorage.getItem('token'),
    alertOk: false,
    alertError: false,
    alert: false,
    documentos: [],
  }),
  computed: {},
  methods: {
    modificar() {
      axios
        .post(`${API_URL}api/usuarios/editar`, {
          email: this.email,
          nombre: this.nombre,
          apellido: this.apellido,
          cuit: this.cuit,
          dni: this.dni,
          razonSocial: this.razonSocial,
          sectorProductivo: this.sectorProductivo,
          tipoCliente: this.tipoCliente,
          rol_id: 3,
          id: localStorage.getItem('usuario_id'),
          token: this.token,
        })
        .then((response) => {
          if (response.data.status === 'error') {
            this.mensaje = response.data.message;
            this.alertError = true;
          } else {
            this.alertError = false;
            this.mensaje = response.data.message;
            this.alertOk = true;
            window.setInterval(() => {
              this.alertOk = false;
            }, 2000);
          }
        })
        .catch(() => {
          this.mensaje = 'Hubo un problema al modificar los datos del usuario';
          this.alertError = true;
        });
    },
    volver() {
      this.$router.push('/');
    },
    transformDate(unixDate) {
      const date = new Date(unixDate);
      return `${date.getDay()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    },
    async loadSectorProductivos() {
      const url = `${API_URL}api/sectoresProductivos`;
      await axios
        .get(url)
        .then((response) => {
          if (response.data.status === 'success') {
            this.sectorProductivos = response.data.sectoresProductivos.map((f) => ({
              value: f.id,
              text: f.nombre,
            }));
          } else {
            console.log('Error al obtener los datos');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUser() {
      this.loadSectorProductivos();
      const url = `${API_URL}api/usuarios/${localStorage.getItem('usuario_id')}`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: this.token,
        },
      };
      axios.get(url, header).then((response) => {
        this.user = response.data.user;
        this.client = response.data.client;
        this.email = this.user.email;
        this.telefono = this.user.telefono;
        this.nombre = this.client.nombre;
        this.apellido = this.client.apellido;
        this.cuit = this.client.cuit;
        this.dni = this.client.dni;
        this.razonSocial = this.client.razonSocial;
        this.sectorProductivo = this.client.sectorProductivo;
        this.tipoCliente = this.client.tipoCliente;
      });
    },
    redireccion() {
      const token = localStorage.getItem('token');
      if (token !== 'undefined' && token !== null && token !== '') {
        axios
          .post(`${API_URL}api/usuarios/validarToken`, {
            token: localStorage.getItem('token'),
          })
          .then(() => {
            this.getUser();
          });
      }
    },
    subirDoc(documentoId, tipo) {
      this.mostrarLoading = true;
      this.alertError = false;
      if (documentoId.size > 10 * 1024 * 1024) {
        console.log('El documento excede el tamaño máximo de 10 MB.');
      }
      const url = `${API_URL}api/documentacionPersonal`;
      const header = {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: this.token,
        },
      };
      const formData = new FormData();
      formData.append('documentoId', tipo);
      formData.append('adjunto', documentoId);
      axios
        .post(url, formData, header)
        .then((response) => {
          if (response.data.status === 'success') {
            this.getDocumentos();
          } else {
            this.alertError = true;
            this.mensaje = response.data.message;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    eliminarDoc(documentoId) {
      this.mostrarLoading = true;
      const url = `${API_URL}api/documentacionPersonal`;
      axios.delete(url, { data: { documento_id: documentoId }, headers: { token: this.token } }).then((response) => {
        this.alertError = false;
        this.mensaje = response.data.message;
        this.alertOk = true;
        this.getDocumentos();
        window.setInterval(() => {
          this.alertOk = false;
        }, 2000);
      });
    },
    verDoc(url) {
      const docUrl = `${API_URL}${url}`;
      window.open(docUrl);
    },
    getDocumentos() {
      const url = `${API_URL}api/documentacionPersonal/${localStorage.getItem('cliente_id')}`;
      const header = {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: this.token,
        },
      };
      axios
        .get(url, header)
        .then((response) => {
          if (response.data.status === 'success') {
            this.documentos = response.data.documentacionPersonal;
            this.mostrarLoading = false;
          } else {
            this.documentos = [];
            this.mostrarLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.redireccion();
    this.getDocumentos();
  },
};
</script>
<style>
td {
  text-align: center;
}
</style>
